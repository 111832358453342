
export const getActivitiesForRessourcesTypes = (resourcesTypesArray, theme) => {
    const deleteTypesDouble = []
    if (resourcesTypesArray?.length) {resourcesTypesArray.forEach(
        type => {
            if (
                type.categories?.length
                && !deleteTypesDouble.find(item => item.name === type.categories[0].name)
            ) {
                deleteTypesDouble.push(
                    {
                        id: type.categories[0].id,
                        name: type.categories[0].name,
                    },
                )
            }
        },
    )}

    if (theme === "theme-padel") {return deleteTypesDouble?.filter(type => padelTypes.includes(type.name))}
    else if (theme === "theme-soccer") {return deleteTypesDouble?.filter(type => !padelTypes.includes(type.name))}
    else {return deleteTypesDouble}
}
export const getResourceDisplay = (id, resourceTypeDisplay, mediaPath) => {
    if (id === 8 || id === 10 || id === 14 || id === 17 || id === 20) {return (
        <>
            <img className="summaryIcon" src={mediaPath([ id === 10 ? "/assets/icons/filmed.svg" : "/assets/icons/filmed-grey.svg", "/assets/images/padel/icons/icons-camera-orange.svg" ])} />
            <span>{id === 8  || id === 14 || id === 17 || id === 20 ? "Intérieur" : "Extérieur"}</span>
        </>
    )}
    else if (id === 0 || id === 12 || id === 16 || id === 19 || id === 24 || id === 22) {return "Intérieur"}
    else if (id === 1 || id === 15 || id === 18 || id === 21 || id === 23) {return "Extérieur"}
    else if (id === 13) {return "Foot 7 - Extérieur"}
    else if (id === 2) {return "Foot 3 - Extérieur"}
    else {return resourceTypeDisplay}
}

const padelTypes = [ "Padel", "Padel Single", "Pickleball" ]
